import { apiSlice } from "../../shared/api/apiSlice";
import { RegisterCompleteRequest } from "../../shared/api/idpApi/type";

interface LoginRequest {
    code: string;
    redirectUri?: string;
}

interface AuthorizeAmazonRequest {
    code: string;
    scope: string;
    returnUri: string;
    sharedResourceId?: string | null;
}

export interface AuthorizeAppleRequest {
    authorization: {
        code: string;
        idToken: string;
        state: string;
    };
    user: {
        email?: string;
        name?: {
            firstName?: string;
            lastName?: string;
        };
    };
    sharedResourceId?: string | null;
}

export interface AuthorizeGoogleRequest {
    credential: string;
    sharedResourceId?: string | null;
}

interface AuthorizePasswordRequest {
    email: string;
    password: string;
}

interface AuthorizeResponse {
    accessToken: string;
    idToken: string;
    userId: string;
    email: string;
    expiresAt: string;
}

type OptioanlAuthorizeResponse = Partial<AuthorizeResponse>;

interface AuthorizePasswordResponse extends OptioanlAuthorizeResponse {
    isResetRequired?: boolean;
}

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        authCheck: builder.query<boolean | null, void>({
            //TODO: replace with healthcheck
            query: () => "/auth/check",
            providesTags: ["Authorize"],
        }),
        login: builder.mutation<AuthorizeResponse, LoginRequest>({
            query: (params) => ({
                url: "/auth/authorize",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: "/auth/logout",
                method: "POST",
            }),
        }),
        authorizeAmazon: builder.mutation<AuthorizeResponse, AuthorizeAmazonRequest>({
            query: (params) => ({
                url: "/auth/authorize/amazon",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
        authorizeApple: builder.mutation<AuthorizeResponse, AuthorizeAppleRequest>({
            query: (params) => ({
                url: "/auth/authorize/apple",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
        authorizeGoogle: builder.mutation<AuthorizeResponse, AuthorizeGoogleRequest>({
            query: (params) => ({
                url: "/auth/authorize/google",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
        authorizePassword: builder.mutation<AuthorizePasswordResponse, AuthorizePasswordRequest>({
            query: (params) => ({
                url: "/auth/authorize/password",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
        registerComplete: builder.mutation<AuthorizeResponse, RegisterCompleteRequest>({
            query: (params) => ({
                url: "/auth/register/complete",
                method: "POST",
                body: params,
            }),
        }),
    }),
});

export const {
    useLazyAuthCheckQuery,
    useLoginMutation,
    useLogoutMutation,
    useAuthorizeAmazonMutation,
    useAuthorizeAppleMutation,
    useAuthorizeGoogleMutation,
    useAuthorizePasswordMutation,
    useRegisterCompleteMutation,
} = authApiSlice;
