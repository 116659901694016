import { FunctionComponent, useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Logo from "../../../../assets/Common/main-brinxlogo-second.svg";
import styles from "../../newUser/NewUserImported.module.scss";
import Input from "../../../../shared/ui/Input/Input";
import Button, { ButtonVariant } from "../../../../shared/ui/buttons/Button/Button";
import { useForgotPasswordCompleteMutation } from "../../../../shared/api/idpApi/idpApiSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isNil } from "lodash";
import { useLoginMutation } from "../../../../features/auth/authApiSlice";
import useAuth from "../../../../utils/useAuth";
import { getErrorText, passwordValidatorRequired } from "../../../../shared/lib/helpers/inputValidators";
import { AuthContext } from "../../../../features/auth/AuthContext/AuthContext";
import { NotificationContext } from "../../../../features/UI/Notification/NotificationContextProvider";

interface INewUserForm {
    password: string;
    password2: string;
}

interface IResetPasswordBase {
    email: string;
    authCode: string;
    token: string;
}

const ResetPasswordCompleteBase: FunctionComponent<IResetPasswordBase> = (props) => {
    const [forgotPasswordComplete] = useForgotPasswordCompleteMutation();

    const [login] = useLoginMutation();
    const { setAuthorization } = useAuth();

    const navigate = useNavigate();
    const { turnOnLoader, turnOffLoader } = useContext(AuthContext);
    const { push } = useContext(NotificationContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<INewUserForm>({ mode: "all", criteriaMode: "all" });
    const onSubmit: SubmitHandler<INewUserForm> = (data) => {
        turnOnLoader();
        forgotPasswordComplete({
            email: props.email,
            authCode: props.authCode,
            token: props.token,
            password: data.password,
        })
            .unwrap()
            .then((value) => {
                if (value) {
                    login({ code: value.authorization_Code })
                        .unwrap()
                        .then((response) => {
                            if (response) {
                                setAuthorization(response.accessToken);
                            }
                        });
                    push("Email Confirmed. You will be redirected to the home page");
                } else {
                    push("Error. Please, try again");
                    navigate("/");
                }
            })
            .finally(turnOffLoader);
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <img src={Logo} alt="logo" />
            </div>
            <div className={styles.text}>
                <p>You forgot it again?</p>
                <p>It’s alright buddy.</p>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Input
                    className={styles.input}
                    placeholder="New Password"
                    inputProps={{
                        type: "password",
                        ...register("password", passwordValidatorRequired),
                    }}
                    errorText={getErrorText(errors.password)}
                />
                <Input
                    className={styles.input}
                    placeholder="Confirm Password"
                    inputProps={{
                        type: "password",
                        ...register("password2", {
                            ...passwordValidatorRequired,
                            validate: {
                                ...passwordValidatorRequired.validate,
                                passwordsMatch: (val: string) => {
                                    if (watch("password") !== val) {
                                        return "Your passwords do not match";
                                    }
                                },
                            },
                        }),
                    }}
                    errorText={getErrorText(errors.password2)}
                />
                <Button
                    className={styles.btn}
                    title="Reset password"
                    buttonVariant={ButtonVariant.Yellow}
                    type="submit"
                />
            </form>
        </div>
    );
};

const ResetPasswordWithCompleteParams = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("Token");
    const email = searchParams.get("Email");
    const authCode = searchParams.get("AuthCode");

    if (isNil(token) || isNil(email) || isNil(authCode)) {
        return <div>Corrupted request</div>;
    }
    return <ResetPasswordCompleteBase token={token} email={email} authCode={authCode} />;
};

const ResetPasswordComplete = ResetPasswordWithCompleteParams;
export default ResetPasswordComplete;
