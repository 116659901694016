import BigNumber from "bignumber.js";
import { apiSlice } from "../../shared/api/apiSlice";
import { getQueryUrl } from "../../shared/lib/helpers/api";
import { CheckoutInfo } from "../shop/shopApiSlice";
import { isNil } from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

interface RedeemProductBase {
    id: string;
    collectionId: string;
    productId: string;
    order: number;
}

export interface RedeemProduct extends RedeemProductBase {
    price: BigNumber;
}

interface RawRedeemProduct extends RedeemProductBase {
    price: string;
}

interface GetRedeemProductsRequest {
    productId?: string;
    collectionId?: string;
    isWidgetCollections?: boolean;
}

interface GetRedeemProductsResponse {
    products: RedeemProduct[];
}

interface GetRawRedeemProductResponse {
    products: RawRedeemProduct[];
}
interface RedeemFullProductAddition {
    title: string;
    description?: string;
    inventoryQuantity?: number;
    featuredImage: {
        url: string;
        width?: number;
        height?: number;
    };
    featuredPrice: {
        priceUsdCents: number;
        compareAtPriceUsdCents?: number;
    };
}
export interface RedeemFullProduct extends RedeemProduct, RedeemFullProductAddition {}

interface RawRedeemFullProduct extends RawRedeemProduct, RedeemFullProductAddition {}

interface GetRedeemFullProductsRequest {
    productId?: string;
    collectionId?: string;
    collectionIds?: string[];
    top?: number;
}

interface GetRedeemFullProductsResponse {
    products: RedeemFullProduct[];
}

interface RawGetRedeemFullProductsResponse {
    products: RawRedeemFullProduct[];
}

interface UpdateRedeemRequest {
    collections: {
        id: string;
        from: BigNumber;
        to: BigNumber | null;
    }[];
    products: {
        collectionId: string;
        productId: string;
        order: number;
        price: BigNumber;
    }[];
}

interface GetRedeemCollectionRequest {
    id?: string;
    isWidgetCollections?: boolean;
}

interface GetRedeemCollectionResponse {
    collections: { id: string; stage: number; from: BigNumber; to: BigNumber | null }[];
}

interface RawGetRedeemCollectionResponse {
    collections: { id: string; name: string; stage: number; from: string; to: string }[];
}

interface AddRedeemCartProductRequest {
    collectionId: string;
    productVariantId: string;
    quantity: number;
    shareResourceId: string | undefined;
}

export interface RedeemItNowRequest {
    collectionId: string;
    productVariantId: string;
    quantity: number;
    shareResourceId: string | undefined;
}

export const redeemApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRedeemProducts: builder.query<GetRawRedeemProductResponse, GetRedeemProductsRequest>({
            query: (request) => {
                return getQueryUrl(["/redeem/products"], request);
            },
            providesTags: ["Redeem-Products"],
        }),
        getRedeemFullProducts: builder.query<RawGetRedeemFullProductsResponse, GetRedeemFullProductsRequest>({
            query: (args) => {
                return getQueryUrl("redeem/products/full", args);
            },
            providesTags: ["Redeem-Products"],
        }),
        updateRedeem: builder.mutation<boolean | null, UpdateRedeemRequest>({
            query: (body) => ({
                url: getQueryUrl(["redeem"]),
                method: "PUT",
                body: body,
            }),
            invalidatesTags: ["Redeem-Products", "RedeemCollections"],
        }),
        getRedeemCollections: builder.query<RawGetRedeemCollectionResponse, GetRedeemCollectionRequest>({
            query: (args) => {
                return getQueryUrl("redeem/collections", args);
            },
            providesTags: ["RedeemCollections"],
        }),

        addRedeemCartProduct: builder.mutation<string, AddRedeemCartProductRequest>({
            query: (body) => {
                return {
                    url: getQueryUrl(["redeem", "cart", "product"]),
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["CartProducts"],
        }),
        redeemItNow: builder.mutation<CheckoutInfo | null, RedeemItNowRequest>({
            query: (body) => ({
                url: getQueryUrl(["redeem", "redeem-it-now", "payment-intent"]),
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const {
    useUpdateRedeemMutation,

    useAddRedeemCartProductMutation,
    useRedeemItNowMutation,

    usePrefetch,
} = redeemApiSlice;

export const useGetRedeemProductsQuery = (
    queryData: GetRedeemProductsRequest | typeof skipToken,
): { data: GetRedeemProductsResponse | undefined; isLoading: boolean; isFetching: boolean } => {
    const { data, isLoading, isFetching } = redeemApiSlice.useGetRedeemProductsQuery(queryData);

    const transformedData = useMemo(
        () =>
            data
                ? {
                      products: data.products.map((product) => ({
                          ...product,
                          price: BigNumber(product.price),
                      })),
                  }
                : undefined,
        [data],
    );

    return {
        data: transformedData,
        isLoading,
        isFetching,
    };
};

export const useGetRedeemFullProductsQuery = (
    queryData: GetRedeemFullProductsRequest | typeof skipToken,
): { data: GetRedeemFullProductsResponse | undefined; isLoading: boolean; isFetching: boolean } => {
    const { data, isLoading, isFetching } = redeemApiSlice.useGetRedeemFullProductsQuery(queryData);

    const transformedData = useMemo(
        () =>
            data
                ? {
                      products: data.products.map((product) => ({
                          ...product,
                          price: BigNumber(product.price),
                      })),
                  }
                : undefined,
        [data],
    );

    return {
        data: transformedData,
        isLoading,
        isFetching,
    };
};

export const useGetRedeemCollectionsQuery = (
    queryData: GetRedeemCollectionRequest,
): { data: GetRedeemCollectionResponse | undefined; isLoading: boolean; isFetching: boolean } => {
    const { data, isLoading, isFetching } = redeemApiSlice.useGetRedeemCollectionsQuery(queryData);

    const transformedData = useMemo(
        () =>
            data
                ? {
                      collections: data.collections.map((collection) => ({
                          ...collection,
                          from: BigNumber(collection.from),
                          to: !isNil(collection.to) ? BigNumber(collection.to) : null,
                      })),
                  }
                : undefined,
        [data],
    );

    return {
        data: transformedData,
        isLoading,
        isFetching,
    };
};
