import { useCallback, useEffect } from "react";
import { apiSlice } from "../shared/api/apiSlice";
import { authSliceReducers } from "../shared/lib/authSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { getApps, initializeApp } from "firebase/app";
import { FirebaseMessaging, GetTokenOptions } from "@capacitor-firebase/messaging";
import firebaseTopic from "../features/notifications/firebaseTopic";
import { Capacitor } from "@capacitor/core";
import {
    useSubscribeTokenToTopicMutation,
    useUnsubscribeTokenFromTopicMutation,
} from "../features/notifications/notificationsApiSlice";
import { useLogoutMutation } from "../features/auth/authApiSlice";
import { firebaseConfig } from "../app/firebase";
import { useScript } from "../shared/lib/hooks";
import useCart from "../entities/cart/lib/hooks/useCart";
import { setBrinxBux } from "../entities/profile/model/profileSlice";
import useResetApiStates from "../shared/lib/hooks/useResetApiStates";

const useAuth = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { initShopCart, resetShopCart } = useCart();

    const token = useAppSelector((state) => state.auth.authorizationToken);

    const getAuthorization = useCallback(() => token, [token]);

    //#region Notifications
    const [subscribeTokenToTopic] = useSubscribeTokenToTopicMutation();
    const [unsubscribeTokenFromTopic] = useUnsubscribeTokenFromTopicMutation();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            return;
        }

        if (getApps().length === 0) {
            initializeApp(firebaseConfig);
        }
    }, []);

    const requestPermissions = async (): Promise<void> => {
        await FirebaseMessaging.requestPermissions();
    };

    const subscribeTokenToGeneral = useCallback(
        async (attemptsCount: number): Promise<void> => {
            if (attemptsCount > 10) {
                console.warn("Failed to subscribe to the topic.");
            }

            const options: GetTokenOptions = {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            };

            if (Capacitor.getPlatform() === "web" && "serviceWorker" in navigator) {
                options.serviceWorkerRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
            }

            try {
                const { token } = await FirebaseMessaging.getToken(options);
                if (Capacitor.getPlatform() === "web") {
                    subscribeTokenToTopic({ token, topic: firebaseTopic.general });
                } else {
                    FirebaseMessaging.subscribeToTopic({ topic: firebaseTopic.general });
                }
            } catch {
                subscribeTokenToGeneral(attemptsCount++);
            }
        },
        [subscribeTokenToTopic],
    );
    //#endregion

    const setAuthorization = useCallback(
        (authorizationToken: string | undefined) => {
            const triedAccess = localStorage.getItem("triedAccess");
            const redirectTo =
                triedAccess === null ? "/" : triedAccess?.at(0) === "/" ? triedAccess : `/${triedAccess}`;

            dispatch(authSliceReducers.setAuthorizationToken(authorizationToken));

            if (authorizationToken) localStorage.setItem("AuthorizationToken", authorizationToken);
            dispatch(apiSlice.util.invalidateTags(["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"]));
            localStorage.removeItem("triedAccess");

            initShopCart();

            navigate(redirectTo ?? "/");
            requestPermissions().then(() => subscribeTokenToGeneral(0));
        },
        [dispatch, initShopCart, navigate, subscribeTokenToGeneral],
    );

    const [logoutMutation] = useLogoutMutation();
    const [loaded] = useScript("https://accounts.google.com/gsi/client");
    const resetApiStates = useResetApiStates();

    const logout = useCallback(() => {
        if (!Capacitor.isNativePlatform() && loaded && window.google) {
            window.google.accounts.id.disableAutoSelect();
        }

        logoutMutation()
            .unwrap()
            .finally(async () => {
                navigate("/");
                localStorage.removeItem("AuthorizationToken");
                dispatch(authSliceReducers.removeAuthorizationToken());

                if (Capacitor.getPlatform() === "web") {
                    const { token } = await FirebaseMessaging.getToken({
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                    });
                    if (token) {
                        unsubscribeTokenFromTopic({ token, topic: firebaseTopic.general });
                    }
                } else {
                    FirebaseMessaging.unsubscribeFromTopic({ topic: firebaseTopic.general });
                }

                dispatch(setBrinxBux(undefined));
                resetApiStates();

                localStorage.removeItem("lastSelectedProducts");
                localStorage.removeItem("lastSelectedRedeemProducts");
                localStorage.removeItem("triedAccess");

                resetShopCart();
            });
    }, [dispatch, loaded, logoutMutation, navigate, resetApiStates, resetShopCart, unsubscribeTokenFromTopic]);

    return {
        getAuthorization,
        setAuthorization,
        logout,
    };
};

export default useAuth;
