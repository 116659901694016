import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { FirebaseMessaging, NotificationActionPerformedEvent } from "@capacitor-firebase/messaging";
import { openLink } from "../shared/lib/helpers/openLink";
import { FirebaseNotification } from "../entities/firebaseMessaging/model/types";
import { useViewNotificationMutation } from "../features/notifications/notificationsApiSlice";

const AppUrlListener: React.FC<any> = () => {
    const navigate = useNavigate();
    const [viewNotification] = useViewNotificationMutation();

    useEffect(() => {
        const addListener = async () => {
            const appUrlOpenListener = await App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
                const url = new URL(event.url);
                const slug =
                    event.url.startsWith("https://") && url.hostname.endsWith("brinx.tv")
                        ? event.url.split("brinx.tv").pop()
                        : event.url.split(":/").pop();

                if (slug) {
                    navigate(slug);
                }
            });

            return appUrlOpenListener;
        };

        const addListenerResult = addListener();

        return () => {
            addListenerResult.then((result) => result.remove());
        };
    }, [navigate]);

    useEffect(() => {
        const notificationActionPerformedListener = Capacitor.isNativePlatform()
            ? FirebaseMessaging.addListener(
                  "notificationActionPerformed",
                  (event: NotificationActionPerformedEvent) => {
                      const notification = event.notification as FirebaseNotification;
                      openLink(notification.data.link, navigate);
                      viewNotification({ id: notification.data.notificationId });
                  },
              )
            : undefined;

        return () => {
            if (Capacitor.isNativePlatform()) {
                notificationActionPerformedListener?.remove();
            }
        };
    }, [navigate, viewNotification]);

    return null;
};

export default AppUrlListener;
