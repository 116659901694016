import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    ForgotPasswordCompleteRequest,
    ForgotPasswordCompleteResponse,
    RegisterCompleteRequest,
    RegisterCompleteResponse,
    RegisterRequest,
    RegisterResponse,
} from "./type";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_IDP_URL}/api`,
});

export const idpApiSlice = createApi({
    reducerPath: "idpApi",
    tagTypes: [],
    baseQuery,
    endpoints: (builder) => ({
        register: builder.mutation<RegisterResponse, RegisterRequest>({
            query: (params) => ({
                url: "/account/register",
                method: "POST",
                body: params,
            }),
        }),
        registerComplete: builder.mutation<RegisterCompleteResponse, RegisterCompleteRequest>({
            query: (params) => ({
                url: "/account/register/complete",
                method: "POST",
                body: params,
            }),
        }),
        forgotPassword: builder.mutation<boolean, { email: string }>({
            query: (params) => ({
                url: "/account/forgot-password",
                method: "POST",
                body: params,
            }),
        }),
        forgotPasswordComplete: builder.mutation<ForgotPasswordCompleteResponse, ForgotPasswordCompleteRequest>({
            query: (params) => ({
                url: "/account/forgot-password/complete",
                method: "POST",
                body: params,
            }),
        }),
    }),
});

export const {
    useRegisterMutation,
    useRegisterCompleteMutation,
    useForgotPasswordMutation,
    useForgotPasswordCompleteMutation,
} = idpApiSlice;
