import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ProtectedAdminRoute } from "../features/admin/ProtectedAdminRoute";
import React, { FunctionComponent, Suspense } from "react";
import Loader from "../shared/ui/Loader/Loader";
import AmazonRedirected from "./login/amazon/AmazonRedirected";
import IDPConfirmed from "./signup/IDPConfirmed";
import ResetPasswordComplete from "./authentication/resetPassword/Confirm/ResetPasswordComplete";
import useAuth from "../utils/useAuth";
import { useGetAppSettingsQuery } from "../entities/appSettings/api/appSettingsApiSlice";
import { isUndefined } from "lodash";

const SignIn = React.lazy(() => import("./authentication/signin/SignIn"));
const Main = React.lazy(() => import("./main/Main"));
const LivePage = React.lazy(() => import("./livepage/LivePage"));
const LiveIndexPage = React.lazy(() => import("./livepage/LiveIndexPage"));
const VODPage = React.lazy(() => import("./channel/vod/VODPage"));
const VODIndexPage = React.lazy(() => import("./channel/vod/VODIndexPage"));
// const RewardsLevelPerks = React.lazy(() => import("./pages/rewardsLevelPerks/RewardsLevelPerks")
//     .then(module => ({ default: module.RewardsLevelPerks })));
// const PrivacyPolicy = React.lazy(() => import("./pages/privacy-policy/Privacy-policy")
//     .then(module => ({ default: module.PrivacyPolicy })));
const ReturnPolicy = React.lazy(() =>
    import("./returnPolicy/ReturnPolicy").then((module) => ({ default: module.ReturnPolicy })),
);
const TermsAndConditions = React.lazy(() =>
    import("./termsAndConditions/TermsAndConditions").then((module) => ({ default: module.TermsAndConditions })),
);
const SweepstakesRules = React.lazy(() =>
    import("./sweepstakesRules/SweepstakesRules").then((module) => ({ default: module.SweepstakesRules })),
);
const Login = React.lazy(() => import("./login/Login"));
const ShopLayout = React.lazy(() => import("./shop/ShopLayout"));
const Shop = React.lazy(() => import("./shop/Shop"));
const ShopProduct = React.lazy(() => import("./shop/ShopProduct/ShopProduct"));
const ShowsPage = React.lazy(() => import("./shows/ShowsPage"));
const SearchPage = React.lazy(() => import("./search/SearchPage"));
const Settings = React.lazy(() => import("./settings/Settings"));
const Redeem = React.lazy(() => import("./redeem/Redeem"));
const CollectionsList = React.lazy(() => import("./redeem/collectionsList/CollectionList"));
const RedeemProduct = React.lazy(() => import("./redeem/product/RedeemProduct"));
const BrinxMe = React.lazy(() => import("./brinxMe/BrinxMe"));
const News = React.lazy(() => import("./news/News"));
const NewsList = React.lazy(() => import("./news/NewsList/NewsList"));
const Article = React.lazy(() => import("./news/Article/Article"));
const ArticleEditor = React.lazy(() => import("./news/ArticleEditor/ArticleEditor"));
const TestVideo = React.lazy(() => import("./test-video/TestVideo"));
const Share = React.lazy(() => import("./share/Share"));
const PaidChannel = React.lazy(() => import("./channel/paid/PaidChannel"));
const BrinxBux = React.lazy(() => import("./brinxBux/BrinxBux"));
const LeaderBoard = React.lazy(() => import("./leaderboard/Leaderboard"));
const ChatWidget = React.lazy(() => import("../widgets/chat/ChatWidget"));
const LeaderBoardTable = React.lazy(() => import("../widgets/leaderboard/ui/LeaderBoardTable/LeaderBoardTable"));

const NewUserImported = React.lazy(() => import("./authentication/newUser/NewUserImported"));
const ResetPassword = React.lazy(() => import("./authentication/resetPassword/ResetPassword"));
const Registration = React.lazy(() => import("./authentication/registration/Registration"));

const UserStreamReserve = React.lazy(() => import("./userStream/Reserve"));
const UserStreamSetup = React.lazy(() => import("./userStream/Setup/Setup"));
const UserStreamPage = React.lazy(() => import("./userStream/UserStreamPage/UserStreamPage"));
const UserStreams = React.lazy(() => import("./userStream/UserStreams"));

const ProfilePage = React.lazy(() => import("./profile/ProfilePage"));

const PageNotFound = React.lazy(() => import("./errors/PageNotFoundMessage/PageNotFoundMessage"));

const GlobalSettingsPage = React.lazy(() => import("./settings/GlobalSettingsPage/GlobalSettingsPage"));
const GeneralLivePagesSettings = React.lazy(
    () => import("../widgets/pageSettings/LivePageSettings/GeneralLivePagesSettings"),
);
const GeneralPpvPagesSettings = React.lazy(
    () => import("../widgets/pageSettings/PpvPageSettings/GeneralPpvPagesSettings"),
);
const GeneralVodPagesSettings = React.lazy(
    () => import("../widgets/pageSettings/VodPageSettings/GeneralVodPagesSettings"),
);
const CarouselPageSettings = React.lazy(() => import("./settings/CarouselPageSettings/CarouselPageSettings"));
const ShowsSettingsPage = React.lazy(() => import("./settings/ShowsSettingsPage/ShowsSettingsPage"));
const ShopPageSettings = React.lazy(() => import("./settings/ShopPageSettings/ShopPageSettings"));
const NewsSettingsPage = React.lazy(() => import("./settings/NewsSettingsPage/NewsSettingsPage"));
const RedeemPageSettings = React.lazy(() => import("./settings/RedeemPageSettings/RedeemPageSettings"));
const AdminsPageSettings = React.lazy(() => import("./settings/AdminsPageSettings/AdminsPageSettings"));
const SchedulerPageSettings = React.lazy(() => import("./settings/SchedulerPageSettings/SchedulerPageSettings"));
const NotificationsSettings = React.lazy(() => import("./settings/NotificationsSettings/NotificationsSettings"));
const BanWordsSettings = React.lazy(() => import("./settings/BanWordsSettings/BanWordsSettings"));
const AffiliateBrandsSettings = React.lazy(
    () => import("../widgets/affiliateBrand/ui/AffiliateBrandsSettings/AffiliateBrandsSettings"),
);

const ReportSettingsPage = React.lazy(() => import("./settings/ReportSettingsPage/ReportSettingsPage"));
const CompetitionsSettings = React.lazy(
    () => import("../widgets/competitions/ui/CompetitionsSettings/CompetitionsSettings"),
);

interface IProtectedRoute {
    children: React.ReactElement;
}
const ProtectedRoute: FunctionComponent<IProtectedRoute> = ({ children }) => {
    const { getAuthorization } = useAuth();
    const token = getAuthorization();
    const navigate = useNavigate();
    const location = useLocation();

    if (!token) {
        localStorage.setItem("triedAccess", location.pathname);
        navigate("/sign-in");
        return null;
    }

    return children;
};

interface IElementWithLazy {
    element: React.ReactNode;
}
export const ElementWithLazy = (props: IElementWithLazy) => {
    return <Suspense fallback={<Loader />}>{props.element}</Suspense>;
};

export const Routing = () => {
    const { data: isVodAuthorized, isLoading } = useGetAppSettingsQuery({
        paramKey: "isVodAuthorized",
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Routes>
            <Route path="*" element={<ElementWithLazy element={<PageNotFound />} />} />
            <Route path="/" element={<ElementWithLazy element={<Main />} />}>
                <Route index element={<Navigate replace to="/channel/live" />} />
                <Route path="channel">
                    <Route index element={<Navigate replace to="/channel/live" />} />

                    <Route path="live">
                        <Route index element={<ElementWithLazy element={<LiveIndexPage />} />} />
                        <Route path=":urlFriendlyTitle" element={<ElementWithLazy element={<LivePage />} />} />
                    </Route>

                    <Route path="paid">
                        <Route index element={<Navigate replace to="/" />} />
                        <Route path=":urlFriendlyTitle" element={<ElementWithLazy element={<PaidChannel />} />} />
                    </Route>

                    {isVodAuthorized === false && (
                        <Route path=":channelUrlFriendlyTitle">
                            <Route index element={<ElementWithLazy element={<VODIndexPage />} />} />
                            <Route
                                path=":videoUrlFriendlyTitle"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <VODPage />
                                    </Suspense>
                                }
                            />
                        </Route>
                    )}
                </Route>
                {isVodAuthorized === false && (
                    <Route path="shows" element={<ElementWithLazy element={<ShowsPage />} />} />
                )}
                <Route path="shop" element={<ElementWithLazy element={<ShopLayout />} />}>
                    <Route index element={<ElementWithLazy element={<Shop />} />} />
                    <Route path="main" element={<ElementWithLazy element={<Shop />} />} />
                    <Route path="product">
                        <Route index element={<Navigate replace to="/shop" />} />
                        <Route path=":productId" element={<ElementWithLazy element={<ShopProduct />} />} />
                    </Route>
                </Route>
                {/* <Route path="brinxbux-info" element={<ElementWithLazy element={<RewardsLevelPerks />} />}/> */}
                <Route path="sign-in" element={<ElementWithLazy element={<SignIn />} />} />
                <Route
                    path="leaderboard"
                    element={
                        <ProtectedRoute>
                            <ElementWithLazy element={<LeaderBoard />} />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<Navigate replace to="/leaderboard/all-time" />} />
                    <Route
                        path="all-time"
                        element={<ElementWithLazy element={<LeaderBoardTable leaderboardType="all-time" />} />}
                    />
                    <Route
                        path="competition"
                        element={<ElementWithLazy element={<LeaderBoardTable leaderboardType="competition" />} />}
                    />
                </Route>
                <Route
                    path="new-user-imported"
                    element={<ElementWithLazy element={<NewUserImported newPassword={""} confirmPassword={""} />} />}
                />
                <Route path="reset-password">
                    <Route index element={<ElementWithLazy element={<ResetPassword email={""} />} />} />
                    <Route path="confirm" element={<ResetPasswordComplete />} />
                </Route>
                <Route path="registration">
                    <Route index element={<ElementWithLazy element={<Registration />} />} />
                    <Route path="confirm" element={<IDPConfirmed />} />
                </Route>
                {/* <Route path="privacy-policy"
                    element={<ElementWithLazy element={<PrivacyPolicy />} />} element={
                }/> */}
                <Route path="return-policy" element={<ElementWithLazy element={<ReturnPolicy />} />} />
                <Route path="terms-and-conditions" element={<ElementWithLazy element={<TermsAndConditions />} />} />
                <Route path="sweepstakes-official-rules" element={<ElementWithLazy element={<SweepstakesRules />} />} />
                <Route path="share">
                    <Route path=":resourceId" element={<ElementWithLazy element={<Share />} />} />
                </Route>
                <Route path="news">
                    <Route index element={<ElementWithLazy element={<News />} />} />
                    <Route path="article" element={<ElementWithLazy element={<Article />} />} />
                    <Route
                        path="article-editor"
                        element={
                            <ElementWithLazy
                                element={
                                    <ProtectedAdminRoute redirectPath="/">
                                        <ArticleEditor />
                                    </ProtectedAdminRoute>
                                }
                            />
                        }
                    />
                    <Route
                        path="test"
                        element={
                            <ElementWithLazy
                                element={
                                    <ProtectedAdminRoute redirectPath="/">
                                        <NewsList />
                                    </ProtectedAdminRoute>
                                }
                            />
                        }
                    />
                </Route>
            </Route>
            <Route path="/chat">
                <Route
                    path=":chatId"
                    element={
                        <ElementWithLazy
                            element={
                                <ChatWidget
                                    chatId={"getFromParams"}
                                    title=""
                                    timeBetweenMessagesMs={5_000}
                                    messageListOnly
                                />
                            }
                        />
                    }
                />
            </Route>
            <Route
                path="/settings"
                element={
                    <ElementWithLazy
                        element={
                            <ProtectedAdminRoute redirectPath="/">
                                <Settings />
                            </ProtectedAdminRoute>
                        }
                    />
                }
            >
                <Route
                    index
                    path="live"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<GeneralLivePagesSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="global"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<GlobalSettingsPage />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="live"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<GeneralLivePagesSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="ppv"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<GeneralPpvPagesSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="vod"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<GeneralVodPagesSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="carousel"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<CarouselPageSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="shows"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<ShowsSettingsPage />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="shop_page"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<ShopPageSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="news_page"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<NewsSettingsPage />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="redeem_page"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<RedeemPageSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="admins"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<AdminsPageSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="scheduler"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<SchedulerPageSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="notifications"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<NotificationsSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="ban_words"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<BanWordsSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="affiliate_brands"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<AffiliateBrandsSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="dashboard"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<ReportSettingsPage />} />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="competitions"
                    element={
                        <ProtectedAdminRoute redirectPath="/">
                            <ElementWithLazy element={<CompetitionsSettings />} />
                        </ProtectedAdminRoute>
                    }
                />
            </Route>
            <Route path="/login">
                <Route index element={<ElementWithLazy element={<Login />} />} />
                <Route path="amazon" element={<AmazonRedirected />} />
            </Route>
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <ElementWithLazy element={<Main />} />
                    </ProtectedRoute>
                }
            >
                {/* <Route path="user-stream">
                    <Route index element={<Navigate to={"/"} />} />
                    <Route path=":userStreamId">
                        <Route index element={<Navigate to={"reserve"} />} />
                        <Route path="reserve" element={<ElementWithLazy element={<UserStreamReserve />} />} />
                        <Route path="setup" element={<ElementWithLazy element={<UserStreamSetup />} />} />
                    </Route>
                </Route>
                <Route path="user-channel">
                    <Route index element={<Navigate to="/" />} />
                    <Route path=":channelId" element={<ElementWithLazy element={<UserStreamPage />} />} />
                </Route> */}
                <Route path="channel">
                    {(isVodAuthorized || isUndefined(isVodAuthorized)) && (
                        <Route path=":channelUrlFriendlyTitle">
                            <Route index element={<ElementWithLazy element={<VODIndexPage />} />} />
                            <Route path=":videoUrlFriendlyTitle" element={<ElementWithLazy element={<VODPage />} />} />
                        </Route>
                    )}
                </Route>
                <Route path="profile">
                    <Route index element={<ElementWithLazy element={<ProfilePage />} />} />
                </Route>
                {(isVodAuthorized || isUndefined(isVodAuthorized)) && (
                    <Route path="shows" element={<ElementWithLazy element={<ShowsPage />} />} />
                )}
                {/* <Route path="user-channels" element={<ElementWithLazy element={<UserStreams />} />} /> */}
                <Route path="search" element={<ElementWithLazy element={<SearchPage />} />} />
                <Route path="redeem" element={<ElementWithLazy element={<Redeem />} />}>
                    <Route index element={<ElementWithLazy element={<CollectionsList />} />} />
                    <Route path="product">
                        <Route index element={<Navigate replace to="/redeem" />} />
                        <Route path=":productId">
                            <Route index element={<Navigate replace to="/redeem" />} />
                            <Route path=":collectionId" element={<ElementWithLazy element={<RedeemProduct />} />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="brinx-bux" element={<ElementWithLazy element={<BrinxBux />} />} />
                <Route path="brinx-me" element={<ElementWithLazy element={<BrinxMe />} />} />
                <Route
                    path="/test-video"
                    element={
                        <ElementWithLazy
                            element={
                                <ProtectedAdminRoute redirectPath="/">
                                    <TestVideo />
                                </ProtectedAdminRoute>
                            }
                        />
                    }
                />
            </Route>
        </Routes>
    );
};
