import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorizeAmazonMutation } from "../../../features/auth/authApiSlice";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import useAuth from "../../../utils/useAuth";
import { useAppSelector } from "../../../app/hooks";
import { GoogleAnalyticsEvents } from "../../../shared/ga/enums/GoogleAnalyticsEvents";

const AmazonRedirected = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const scope = searchParams.get("scope");

    const navigate = useNavigate();
    const { setAuthorization } = useAuth();

    const [authorize] = useAuthorizeAmazonMutation();

    useEffect(() => {
        if (code && scope) {
            authorize({
                code,
                scope,
                returnUri: `${process.env.REACT_APP_WEB_FRONT_URL}/login/amazon`,
                sharedResourceId: localStorage.getItem("LatestSharedResourceId"),
            })
                .unwrap()
                .then((response) => {
                    if (response) {
                        if (Capacitor.isNativePlatform()) {
                            Browser.close();
                        }
                        window.dataLayer?.push({
                            event: GoogleAnalyticsEvents.Login,
                            user_id: response.userId,
                            event_type: "custom_event",
                        });

                        setAuthorization(response.accessToken);
                    }
                })
                .catch(() => {
                    navigate("/");
                });
        }
    }, []);

    return null;
};

export default AmazonRedirected;
