export enum GoogleAnalyticsEvents {
    Login = "Login",
    Signup = "Signup",
    ShowClick = "Show_Click",
    BetStart = "Bet_Start",
    BetPlace = "Bet_Place",
    AppStoreClick = "App_Store_Click",
    MagicWindowStart = "Magic_Window_Start",
    MagicWindowEnd = "Magic_Window_End",
    XRollClick = "X_Roll_Click",
    SweepstakeStart = "Sweepstake_Start",
    SweepstakeEnter = "Sweepstake_Enter",
    ChatOpen = "Chat_Open",
    ChatMessage = "Chat_Message",
    ShareWidgetOpen = "Share_Widget_Open",
    ShareWidgetClick = "Share_Widget_Click",
    NotFoundPage = "Page404",
}
