import { isAction, isRejectedWithValue, type Middleware, type MiddlewareAPI } from "@reduxjs/toolkit";
import { authSliceReducers } from "./authSlice";

export const unauthorizedMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // @ts-ignore
    if (isAction(action) && isRejectedWithValue(action) && action.payload.status === 401) {
        api.dispatch(authSliceReducers.removeAuthorizationToken());
        localStorage.removeItem("AuthorizationToken");
        window.location.href = "/sign-in";
    }

    return next(action);
};
