import { Capacitor } from "@capacitor/core";
import { authApiSlice } from "../../features/auth/authApiSlice";
import { PayloadAction, createSlice, isAnyOf, isRejectedWithValue } from "@reduxjs/toolkit";

interface IAppState {
    upgradeRequired: boolean | null;
}

let initialState: IAppState = {
    upgradeRequired: null,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        resetUpgradeRequired: (state) => {
            state.upgradeRequired = null;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(authApiSlice.endpoints.authCheck.matchRejected), (state, action) => {
            if (Capacitor.isNativePlatform()) {
                console.log(action);
                if (isRejectedWithValue(action)) {
                    if (action.payload?.status === 426) state.upgradeRequired = true;
                }
            }
        });
    },
});

export const appSliceReducers = appSlice.actions;
