import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICartState {
    id: string | null;
}

let initialState: ICartState = { id: localStorage.getItem("cartId") };

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        saveCartId(state, action: PayloadAction<string | null>) {
            state.id = action.payload;
            if (action.payload === null) {
                localStorage.removeItem("cartId");
                return;
            }

            localStorage.setItem("cartId", action.payload);
        },
    },
});

export const selectCartId = (state: RootState) => state.cart.id;

export const { saveCartId } = cartSlice.actions;
