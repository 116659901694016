import { isNil } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import Loader from "../../shared/ui/Loader/Loader";
import { useRegisterCompleteMutation } from "../../features/auth/authApiSlice";
import useAuth from "../../utils/useAuth";
import { NotificationContext } from "../../features/UI/Notification/NotificationContextProvider";
import { useAppSelector } from "../../app/hooks";
import { GoogleAnalyticsEvents } from "../../shared/ga/enums/GoogleAnalyticsEvents";

interface IDPConfirmedProps {
    token: string;
    email: string;
    lastName: string;
    firstName: string;
    phone: string | null;
    sharedResourceId: string | null;
    authCode: string;
}
const IDPConfirmedBase = (props: IDPConfirmedProps) => {
    const [completeRegistration] = useRegisterCompleteMutation();

    const { setAuthorization } = useAuth();
    const { push } = useContext(NotificationContext);

    const navigate = useNavigate();

    useEffect(() => {
        completeRegistration({
            token: props.token,
            email: props.email,
            authCode: props.authCode,
            lastName: props.lastName,
            firstName: props.firstName,
            phone: props.phone,
            sharedResourceId: props.sharedResourceId,
        })
            .unwrap()
            .then((response) => {
                window.dataLayer?.push({
                    event: GoogleAnalyticsEvents.Signup,
                    user_id: response.userId,
                    event_type: "custom_event",
                });

                setAuthorization(response.accessToken);
                push("Email Confirmed. You will be redirected to the home page");
            })
            .catch(() => {
                push("Error. Please, try again");
                navigate("/");
            });
    }, [
        completeRegistration,
        navigate,
        props.authCode,
        props.email,
        props.firstName,
        props.lastName,
        props.phone,
        props.token,
    ]);

    //TODO: add styles
    return (
        <div>
            Completing registration...
            <Loader />
        </div>
    );
};

const IDPConfirmedWithParams = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("Token");
    const email = searchParams.get("Email");
    const firstName = searchParams.get("FirstName");
    const lastName = searchParams.get("LastName");
    const phone = searchParams.get("Phone");
    const authCode = searchParams.get("AuthCode");
    const sharedResourceId = searchParams.get("SharedResourceId");

    if (isNil(token) || isNil(email) || isNil(authCode) || isNil(firstName) || isNil(lastName)) {
        return <div>Corrupted request</div>;
    }

    return (
        <IDPConfirmedBase
            token={token}
            email={email}
            authCode={decodeURIComponent(authCode)}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            sharedResourceId={sharedResourceId}
        />
    );
};

const IDPConfirmed = IDPConfirmedWithParams;
export default IDPConfirmed;
