import { createSlice } from "@reduxjs/toolkit";

interface IAuthState {
    authorizationToken: string | undefined;
}

let initialState: IAuthState = { authorizationToken: localStorage.getItem("AuthorizationToken") ?? undefined };

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthorizationToken(state, action) {
            state.authorizationToken = action.payload;
        },
        removeAuthorizationToken(state) {
            state.authorizationToken = undefined;
        },
    },
});

export const authSliceReducers = authSlice.actions;
