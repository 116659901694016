import _, { isArray } from "lodash";

type PathItem = string | number | undefined | null;

export const getQueryApiUrl = (path: PathItem | PathItem[], data?: { [s: string]: any } | undefined | null) => {
    const url = getQueryUrl(path, data);
    return `${process.env.REACT_APP_BACK_URL}/${url}`;
};

export const getQueryUrl = (path: PathItem | PathItem[], data?: { [s: string]: any } | undefined | null) => {
    let url = getUrl(path);

    if (_.isEmpty(data)) return url;

    const params = Object.entries(data)
        .filter(([, val]) => !_.isNil(val))
        .map(([key, val]) => {
            if (isArray(val)) {
                return Object.entries(val)
                    .map(([_, v]) => `${key}=${encodeURIComponent(v)}`)
                    .join("&");
            } else {
                return `${key}=${encodeURIComponent(val)}`;
            }
        })
        .join("&");

    if (_.isEmpty(params)) return url;

    url = `${url}${!url.includes("?") ? "?" : "&"}${params}`;

    return url;
};

const getUrl = (path: PathItem | PathItem[]) => {
    if (_.isArray(path)) {
        path = path
            .filter((s) => !_.isNil(s) && (!_.isString(s) || !_.isEmpty(s)))
            .map((s) => (_.isString(s) ? _.trim(s, "/") : s))
            .join("/");
    }

    const url = (path ?? "").toString();
    return url;
};
