import { apiSlice } from "../../../shared/api/apiSlice";
import { getQueryUrl } from "../../../shared/lib/helpers/api";
import { AppSettingsValue, AppSettings, GetAppSettingsRequest, UpdateAppSettingsRequest } from "./types";

export const appSettingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAppSettings: builder.query<AppSettingsValue<keyof AppSettings>, GetAppSettingsRequest>({
            query: (args) => getQueryUrl("app-settings", args),
            providesTags: (_response, _error, request) => {
                return [
                    {
                        type: "AppSettings" as const,
                        id: request.paramKey,
                    },
                ];
            },
        }),
        updateAppSettings: builder.mutation<void, UpdateAppSettingsRequest>({
            query: (body) => ({
                url: "app-settings",
                method: "PATCH",
                body,
            }),
            invalidatesTags: (_response, _error, request) => [{ type: "AppSettings" as const, id: request.paramKey }],
        }),
    }),
});

export const { useGetAppSettingsQuery, useUpdateAppSettingsMutation } = appSettingsApiSlice;
