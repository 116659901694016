import BigNumber from "bignumber.js";
import { apiSlice } from "../../../shared/api/apiSlice";
import { getQueryUrl } from "../../../shared/lib/helpers/api";
import {
    AddBrinxBuxForReadingArticleRequest,
    AddClickRequest,
    AddShareResourceReferralRequest,
    AddShareResourceRequest,
    AddVideoRequest,
    GetBrinxBuxSettingsResponse,
    GetLastBrinxBuxResetResponse,
    RawGetBrinxBuxSettingsResponse,
    ResetUserBalancesRequest,
    UpdateBrinxBuxSettingsRequest,
} from "./types";
import { useCallback, useMemo } from "react";

export const brinxBuxApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBrinxBux: builder.query<string, void>({
            query: () => "/brinx-bux",
            providesTags: ["BrinxBux"],
        }),
        addClick: builder.mutation<boolean, AddClickRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "click"]),
                method: "POST",
                body: body,
            }),
        }),
        addVideo: builder.mutation<boolean, AddVideoRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "video"]),
                method: "POST",
                body: body,
            }),
        }),
        addBrinxBuxForReadingArticle: builder.mutation<boolean, AddBrinxBuxForReadingArticleRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "read-article"]),
                method: "POST",
                body: body,
            }),
        }),
        addShareResource: builder.mutation<boolean, AddShareResourceRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "share-resource"]),
                method: "POST",
                body: body,
            }),
        }),
        addShareResourceReferral: builder.mutation<boolean, AddShareResourceReferralRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "share-resource", "referral"]),
                method: "POST",
                body: body,
            }),
        }),
        getSettings: builder.query<RawGetBrinxBuxSettingsResponse, void>({
            query: () => "/brinx-bux/settings",
            providesTags: ["BrinxBux-Settings"],
        }),
        updateSettings: builder.mutation<void, UpdateBrinxBuxSettingsRequest>({
            query: (body) => ({
                url: "/brinx-bux/settings",
                method: "PUT",
                body: body,
            }),
            invalidatesTags: ["BrinxBux-Settings"],
        }),
        resetUserBalances: builder.mutation<void, ResetUserBalancesRequest>({
            query: (body) => ({
                url: getQueryUrl(["brinx-bux", "reset"]),
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["BrinxBux", "ResetHistory"],
        }),
        getLastResetInfo: builder.query<GetLastBrinxBuxResetResponse, void>({
            query: () => getQueryUrl(["brinx-bux", "reset"]),
            providesTags: ["ResetHistory"],
        }),
    }),
});

export const {
    useAddClickMutation,
    useAddVideoMutation,
    useAddBrinxBuxForReadingArticleMutation,
    useAddShareResourceMutation,
    useAddShareResourceReferralMutation,
    useUpdateSettingsMutation,
    useResetUserBalancesMutation,
    useGetLastResetInfoQuery,
} = brinxBuxApiSlice;

export const useLazyGetBrinxBuxQuery = (): {
    trigger: () => Promise<BigNumber>;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
} => {
    const [getBrinxBux, result] = brinxBuxApiSlice.useLazyGetBrinxBuxQuery();

    const transformedTrigger = useCallback(async () => {
        const data = await getBrinxBux().unwrap();
        return BigNumber(data ? data : 0);
    }, [getBrinxBux]);

    return {
        trigger: transformedTrigger,
        isLoading: result.isLoading,
        isSuccess: result.isSuccess,
        isError: result.isError,
    };
};

export const useGetSettingsQuery = (): {
    data: GetBrinxBuxSettingsResponse | undefined;
    isLoading: boolean;
} => {
    const { data, isLoading } = brinxBuxApiSlice.useGetSettingsQuery();

    const transformedData = useMemo(
        () =>
            data
                ? {
                      settings: {
                          ...data.settings,
                          max: BigNumber(data.settings.max),
                      },
                  }
                : undefined,
        [data],
    );

    return {
        data: transformedData,
        isLoading,
    };
};
