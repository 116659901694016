import { FieldError, RegisterOptions } from "react-hook-form";

const specificCharacterError = (s: string, testPattern: RegExp, invalidCharactersPattern: RegExp) => {
    if (s.length === 0) return true;
    const isValid = testPattern.test(s);
    if (isValid) return isValid;
    const invalidCharacters = [...new Set(s.replace(invalidCharactersPattern, "").split(""))];
    if (invalidCharacters.length === 0) return "Wrong Format";
    let errorMessage = "";
    for (let i = 0; i < invalidCharacters.length; i++) {
        const invalidCharacter = invalidCharacters[i];
        errorMessage += invalidCharacter + (i === invalidCharacters.length - 1 ? " " : ", ");
    }
    errorMessage += "not accepted";
    return errorMessage;
};

export const emailValidatorRequired: RegisterOptions = {
    validate: {
        validDomain: (s) => /@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i.test(s) || "Email domain required",
        validPattern: (s: string) =>
            specificCharacterError(
                s,
                /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i,
                /[a-z0-9.!#$@%&’*+/=?^_`{|}~-]+/gi,
            ),
    },
    required: {
        value: true,
        message: "Required field",
    },
    minLength: {
        value: 5,
        message: "Should be 5+ characters long",
    },
    maxLength: {
        value: 50,
        message: "Cannot enter more than 50 symbols",
    },
};

export const passwordValidatorRequired: RegisterOptions = {
    validate: {
        validPattern: (s: string) => specificCharacterError(s, /^[A-Za-z0-9 !@#$&*]+$/i, /[A-Za-z0-9 !@#$&*]+/gi),
    },
    required: {
        value: true,
        message: "Must use 6 characters, mix letters & numbers",
    },
    minLength: {
        value: 6,
        message: "Must use 6 characters, mix letters & numbers",
    },
    maxLength: {
        value: 50,
        message: "Cannot enter more than 50 symbols",
    },
};

export const nameValidatorRequired: RegisterOptions = {
    validate: {
        validPattern: (s: string) => specificCharacterError(s, /^[a-z0-9 ]+$/i, /[a-z0-9 ]+/gi),
    },
    required: {
        value: true,
        message: "Required field",
    },
    minLength: {
        value: 2,
        message: "Should be 2+ characters long",
    },
    maxLength: {
        value: 30,
        message: "Cannot enter more than 30 symbols",
    },
};

export const phoneValidator: RegisterOptions = {
    pattern: {
        value: /^(\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d)|(\+1)$/,
        message: "Digits only",
    },
    required: false,
    minLength: {
        value: 11,
        message: "Should be 10+ digits long",
    },
    maxLength: {
        value: 21,
        message: "Cannot enter more than 20 digits",
    },
};

export const phoneValidatorRequired: RegisterOptions = {
    pattern: {
        value: /^(\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d)|(\+1)$/,
        message: "Digits only",
    },
    required: {
        value: true,
        message: "Required field",
    },
    minLength: {
        value: 11,
        message: "Should be 10+ digits long",
    },
    maxLength: {
        value: 21,
        message: "Cannot enter more than 20 digits",
    },
};

export const notEmptyRequiredValidator: RegisterOptions = {
    required: {
        value: true,
        message: "Required field",
    },
    pattern: { value: /\S/, message: "Cannot be empty" },
};

export const notEmptyValidator: RegisterOptions = {
    pattern: { value: /\S/, message: "Cannot be empty" },
};

export const getErrorText = (error: FieldError | undefined) => {
    if (!error) return "";
    if (!error.types) return error.message ?? "";
    let errorMessage = "";
    Object.entries(error.types).forEach(([key, value]) => {
        errorMessage += value + ". ";
    });
    return errorMessage;
};
